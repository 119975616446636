import log from 'loglevel';

import { TextFile } from '@/data/datatypes/TextFile';
import { MiniApp } from '@/data/tasks/MiniApp';
import { Task } from '@/data/tasks/Task';
import { TasksTable } from '@/data/tasks/TasksTable';

import { ChatMessage } from '../datatypes/chat/ChatMessage';
import { EntryThumbnail } from '../datatypes/EntryThumbnails';
import { Member } from '../datatypes/Member';
import { TrackEntry } from '../datatypes/TrackEntry';
import { FullUserDetails } from '../datatypes/UserDetails';
import AbstractDb from './AbstractDb';
import StoredItem from './StoredItem';

interface EntityWithId {
  id: string;
}

export default class ObjectStores extends AbstractDb {
  public static TRACK_MEMBERS_STORE: string = 'trackMembers';
  public static TRACK_ENTRIES_STORE: string = 'trackEntries';
  public static ENTRY_TEXT_FILES_STORE: string = 'entryTextFiles';
  public static TRACK_ACTIONS_STORE: string = 'trackActions';
  public static TRACKS_STORE: string = 'tracks';
  public static TENANTS_STORE: string = 'tenants';
  public static CURRENT_USER_STORE: string = 'currentUser';
  public static TENANT_USERS_STORE: string = 'tenantUsers';
  public static EXTERNAL_USERS_STORE: string = 'sharedUsers';
  public static ONLINE_USERS_STORE: string = 'onlineUsers';
  public static TRACK_ONLINE_STATUSES_STORE: string = 'trackOnlineStatuses';
  public static APP_ACCOUNTS_STORE: string = 'appAccounts';
  public static CHAT_MESSAGES_STORE: string = 'chatMessages';
  public static COMMENTS_STORE: string = 'comments';
  public static CONNECTION_STATE_STORE: string = 'connectionState';
  public static PINNED_TRACK_ITEMS_STORE: string = 'pinnedTrackItems';
  public static USER_TOKEN_STORE: string = 'userToken';
  public static MINI_APPS_STORE: string = 'miniApps';
  public static TRACK_MINI_APPS_STORE: string = 'trackMiniApps';
  public static TASKS_TABLE_STORE: string = 'tasksTable';
  public static TRACK_TASKS_TABLE_STORE: string = 'trackTasksTable';
  public static TASKS_STORE: string = 'tasks';
  public static KB_ARTICLES_STORE: string = 'kbArticles';
  public static KB_SUBSCRIPTION_CONFIG_STORE: string = 'kbArticleSubscriptionConfig';
  public static CHAT_HISTORY_STATE_STORE: string = 'chatHistoryState';
  public static UI_FLOWS_STORE: string = 'uiFlows';
  // This is no longer used. Added in v14 and removed in v24
  public static SEMANTIC_TAGS_STORE: string = 'semanticTags';
  public static ENTRY_THUMBNAIL_STORE: string = 'entryThumbnails';
  public static RULESETS_STORE: string = 'rulesets';
  public static NAV_BAR_ITEMS_STORE = 'navBarItems';
  public static MINI_APP_VIEWS_STORE: string = 'miniAppViews';
  public static TRACK_MINI_APP_VIEWS_STORE: string = 'trackMiniAppViews';
  public static APP_DEVELOPMENT_VERSION_STORE: string = 'appDevelopmentVersions';
  public static APP_RELEASES_STORE: string = 'appReleases';
  public static APP_ENVIRONMENT_STORE: string = 'appEnvironments';
  public static DEPLOYED_APP_STORE: string = 'deployedApps';
  public static RELEASED_APP_STORE: string = 'releasedApps';
  public static DEPLOYMENT_HISTORY_STORE: string = 'deploymenthistory';
  public static MINI_APP_VIEW_THEMES_STORE: string = 'miniAppViewThemes';
  public static MINI_APP_VIEW_CONDITIONAL_OVERRIDES_STORE: string = 'miniAppViewConditionalOverrides';

  public static TIMESTAMP_INDEX: string = 'timestamp';
  public static TRACK_ID_INDEX: string = 'trackId';
  public static ENTRY_ID_INDEX: string = 'entryId';
  public static TIMESTAMP_TRACK_ID_INDEX: string = 'timestampAndTrackId';
  public static TIMESTAMP_ENTRY_ID_INDEX: string = 'timestampAndEntryId';
  public static TRACK_ID_MESSAGE_DATE_INDEX: string = 'trackIdAndMessageDate';
  public static ENTRY_ID_MESSAGE_DATE_INDEX: string = 'entryIdAndMessageDate';
  // gsmith: This seemingly isn't functionally equivalent to TIMESTAMP_TRACK_ID_INDEX.
  // This variant allows us to find the latest timestamp for a track. The opposite didn't work when I tried it.
  public static TRACK_ID_TIMESTAMP_INDEX: string = 'trackIdAndTimestamp';
  public static USER_ID_INDEX: string = 'userId';
  public static APP_ID_INDEX: string = 'appId';
  public static TENANT_ID_INDEX: string = 'tenantId';
  public static ENVIRONMENT_ID_INDEX: string = 'environmentId';
  public static MINI_APP_ID_INDEX: string = 'miniAppId';

  private exceededQuotaHandled: boolean = false;
  private dbUpgradeRequestedCallback: (db: IDBDatabase) => void;

  constructor(dbUpgradeRequestedCallback: (db: IDBDatabase) => void) {
    super('dataStores', 25);
    this.dbUpgradeRequestedCallback = dbUpgradeRequestedCallback;
  }

  async clearAllDataStores(): Promise<void> {
    const storesToClear: string[] = [
      ObjectStores.TRACKS_STORE,
      ObjectStores.TRACK_ENTRIES_STORE,
      ObjectStores.ENTRY_TEXT_FILES_STORE,
      ObjectStores.TRACK_MEMBERS_STORE,
      ObjectStores.TENANTS_STORE,
      ObjectStores.EXTERNAL_USERS_STORE,
      ObjectStores.APP_ACCOUNTS_STORE,
      ObjectStores.CHAT_MESSAGES_STORE,
      ObjectStores.CURRENT_USER_STORE,
      ObjectStores.TRACK_ONLINE_STATUSES_STORE,
      ObjectStores.PINNED_TRACK_ITEMS_STORE,
      ObjectStores.MINI_APPS_STORE,
      ObjectStores.TRACK_MINI_APPS_STORE,
      ObjectStores.TASKS_TABLE_STORE,
      ObjectStores.TRACK_TASKS_TABLE_STORE,
      ObjectStores.TASKS_STORE,
      ObjectStores.KB_ARTICLES_STORE,
      ObjectStores.UI_FLOWS_STORE,
      ObjectStores.CHAT_HISTORY_STATE_STORE,
      ObjectStores.ENTRY_THUMBNAIL_STORE,
      ObjectStores.RULESETS_STORE,
      ObjectStores.NAV_BAR_ITEMS_STORE,
      ObjectStores.MINI_APP_VIEWS_STORE,
      ObjectStores.TRACK_MINI_APP_VIEWS_STORE,
      ObjectStores.KB_SUBSCRIPTION_CONFIG_STORE,
      ObjectStores.APP_DEVELOPMENT_VERSION_STORE,
      ObjectStores.APP_RELEASES_STORE,
      ObjectStores.APP_ENVIRONMENT_STORE,
      ObjectStores.DEPLOYED_APP_STORE,
      ObjectStores.RELEASED_APP_STORE,
      ObjectStores.DEPLOYMENT_HISTORY_STORE
    ];
    const promises: Array<Promise<void>> = [];
    for (const store of storesToClear) {
      promises.push(this.clearObjectStore(store));
    }
    log.debug('beginning to clear stores');
    await Promise.all(promises);
    log.debug('all stores cleared');
  }

  async updateDbVersion(request: IDBOpenDBRequest, oldVersion: number, newVersion: number | null): Promise<void> {
    return new Promise((resolve) => {
      log.debug(`Upgrading DB from ${oldVersion} to ${newVersion}`);

      if (oldVersion < 1) {
        log.debug('Running v1 upgrade');
        const trackStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TRACKS_STORE,
          { keyPath: 'entity.id' });
        trackStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const entriesStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TRACK_ENTRIES_STORE,
          { keyPath: 'entity.id' });
        entriesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        entriesStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });

        const membersStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TRACK_MEMBERS_STORE,
          { keyPath: 'entity.id' });
        membersStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        membersStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });

        const actionsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TRACK_ACTIONS_STORE,
          { keyPath: 'entity.id' });
        actionsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        actionsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });

        const tenantsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TENANTS_STORE,
          { keyPath: 'entity.id' });
        tenantsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const tenantUsersStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.TENANT_USERS_STORE,
          { keyPath: 'entity.id' });
        tenantUsersStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const externalUsersStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.EXTERNAL_USERS_STORE,
          { keyPath: 'entity.user.id' });
        externalUsersStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const appAccountsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.APP_ACCOUNTS_STORE,
          { keyPath: 'entity.id' });
        appAccountsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const chatMessagesStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.CHAT_MESSAGES_STORE,
          { keyPath: 'entity.id' });
        chatMessagesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        chatMessagesStore.createIndex(ObjectStores.TIMESTAMP_TRACK_ID_INDEX,
          ['timestamp', 'entity.trackId'], { unique: false });
        chatMessagesStore.createIndex(ObjectStores.TRACK_ID_MESSAGE_DATE_INDEX,
          ['entity.trackId', 'entity.date'], { unique: false });

        const commentsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.COMMENTS_STORE,
          { keyPath: 'entity.id' });
        commentsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        commentsStore.createIndex(ObjectStores.TIMESTAMP_ENTRY_ID_INDEX,
          ['timestamp', 'entity.entryId'], { unique: false });
        commentsStore.createIndex(ObjectStores.ENTRY_ID_MESSAGE_DATE_INDEX,
          ['entity.entryId', 'entity.date'], { unique: false });

        this.db.createObjectStore(ObjectStores.CURRENT_USER_STORE, { keyPath: 'id' });

        this.db.createObjectStore(ObjectStores.CONNECTION_STATE_STORE, { keyPath: 'id' });
      }

      if (oldVersion < 2) {
        // The structure of the objects in this store has changed:
        this.db.deleteObjectStore(ObjectStores.EXTERNAL_USERS_STORE);
        const externalUsersStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.EXTERNAL_USERS_STORE,
          { keyPath: 'entity.id' });
        externalUsersStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 3) {
        const transaction: IDBTransaction | null = request.transaction;
        if (transaction) {
          // Add userId index to members store
          const membersStore: IDBObjectStore = transaction.objectStore(ObjectStores.TRACK_MEMBERS_STORE);
          membersStore.createIndex(ObjectStores.USER_ID_INDEX, 'entity.userId', { unique: false });

          // Add trackId index to chat messages and comments stores
          const chatMessagesStore: IDBObjectStore = transaction.objectStore(ObjectStores.CHAT_MESSAGES_STORE);
          chatMessagesStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
          const commentsMessagesStore: IDBObjectStore = transaction.objectStore(ObjectStores.COMMENTS_STORE);
          commentsMessagesStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        }
      }

      if (oldVersion < 4) {
        const textFilesStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.ENTRY_TEXT_FILES_STORE,
          { keyPath: 'entity.id' });
        textFilesStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        textFilesStore.createIndex(ObjectStores.ENTRY_ID_INDEX, 'entity.entryId', { unique: false });
      }

      if (oldVersion < 5) {
        this.db.createObjectStore(ObjectStores.ONLINE_USERS_STORE, { keyPath: 'id' });
      }

      if (oldVersion < 6) {
        const onlineStatusesStore = this.db.createObjectStore(ObjectStores.TRACK_ONLINE_STATUSES_STORE,
          { keyPath: 'entity.trackId' });
        onlineStatusesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 7) {
        const pinnedTrackItemsStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.PINNED_TRACK_ITEMS_STORE, { keyPath: 'entity.id' });
        pinnedTrackItemsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        pinnedTrackItemsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
      }

      if (oldVersion < 8) {
        const minAppsStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.MINI_APPS_STORE, { keyPath: 'entity.id' });
        minAppsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        const tasksTableStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.TASKS_TABLE_STORE, { keyPath: 'entity.id' });
        tasksTableStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        const tasksItemsStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.TASKS_STORE, { keyPath: 'entity.id' });
        tasksItemsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
      }

      if (oldVersion < 9) {
        this.db.createObjectStore(ObjectStores.KB_ARTICLES_STORE, { keyPath: 'entity.id' });
      }

      if (oldVersion < 10) {
        // Add timestamp indexes to tasks-related stores
        const transaction: IDBTransaction | null = request.transaction;
        if (transaction) {
          const miniAppsStore: IDBObjectStore = transaction.objectStore(ObjectStores.MINI_APPS_STORE);
          miniAppsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
          const tasksTableStore: IDBObjectStore = transaction.objectStore(ObjectStores.TASKS_TABLE_STORE);
          tasksTableStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
          const tasksStore: IDBObjectStore = transaction.objectStore(ObjectStores.TASKS_STORE);
          tasksStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        }
      }

      if (oldVersion < 11) {
        this.db.createObjectStore(ObjectStores.CHAT_HISTORY_STATE_STORE, { keyPath: ['trackId', 'entryId'] });
      }

      if (oldVersion < 12) {
        const uiFlowsStore: IDBObjectStore =
          this.db.createObjectStore(ObjectStores.UI_FLOWS_STORE, { keyPath: 'entity.id' });
        uiFlowsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        uiFlowsStore.createIndex(ObjectStores.APP_ID_INDEX, 'entity.appId', { unique: false });
      }

      if (oldVersion < 13) {
        const transaction: IDBTransaction | null = request.transaction;
        if (transaction) {
          // Clear old data from mini-apps and task tables stores
          const miniAppsStore: IDBObjectStore = transaction.objectStore(ObjectStores.MINI_APPS_STORE);
          miniAppsStore.clear();
          const tablesStore: IDBObjectStore = transaction.objectStore(ObjectStores.TASKS_TABLE_STORE);
          tablesStore.clear();

          // Turn them into non-track-level stores
          miniAppsStore.deleteIndex(ObjectStores.TRACK_ID_INDEX);
          miniAppsStore.createIndex(ObjectStores.TENANT_ID_INDEX, 'entity.tenantId', { unique: false });
          tablesStore.deleteIndex(ObjectStores.TRACK_ID_INDEX);
          tablesStore.createIndex(ObjectStores.TENANT_ID_INDEX, 'entity.tenantId', { unique: false });

          // Create new stores to hold the track-level objects
          const trackMiniAppsStore: IDBObjectStore = this.db.createObjectStore(
            ObjectStores.TRACK_MINI_APPS_STORE, { keyPath: 'entity.id' });
          trackMiniAppsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
          trackMiniAppsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
          const trackTasksTableStore: IDBObjectStore = this.db.createObjectStore(
            ObjectStores.TRACK_TASKS_TABLE_STORE, { keyPath: 'entity.id' });
          trackTasksTableStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
          trackTasksTableStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        }
      }

      // I've left this in so that we can see what it was, but this was subsequently removed in v24
      // if (oldVersion < 14) {
      //   const semanticTagsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.SEMANTIC_TAGS_STORE,
      //     { keyPath: 'entity.id' });
      //   semanticTagsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      //   semanticTagsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
      // }

      if (oldVersion < 15) {
        const entryThumbnailsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.ENTRY_THUMBNAIL_STORE,
          { keyPath: 'entity.id' });
        entryThumbnailsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        entryThumbnailsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        entryThumbnailsStore.createIndex(ObjectStores.TRACK_ID_TIMESTAMP_INDEX,
          ['entity.trackId', 'timestamp'], { unique: false });
      }

      if (oldVersion < 16) {
        const rulesetsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.RULESETS_STORE,
          { keyPath: 'entity.id' });
        rulesetsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 17) {
        this.db.deleteObjectStore(ObjectStores.TRACK_ACTIONS_STORE);
      }

      if (oldVersion < 18) {
        const navBarItemsStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.NAV_BAR_ITEMS_STORE,
          { keyPath: 'entity.id' });
        navBarItemsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 19) {
        const miniAppViewsStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.MINI_APP_VIEWS_STORE, { keyPath: 'entity.id' });
        miniAppViewsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        miniAppViewsStore.createIndex(ObjectStores.TENANT_ID_INDEX, 'entity.tenantId', { unique: false });

        const trackMiniAppViewsStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.TRACK_MINI_APP_VIEWS_STORE, { keyPath: 'entity.id' });
        trackMiniAppViewsStore.createIndex(ObjectStores.TRACK_ID_INDEX, 'entity.trackId', { unique: false });
        trackMiniAppViewsStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 20) {
        this.db.createObjectStore(ObjectStores.KB_SUBSCRIPTION_CONFIG_STORE, { keyPath: 'id' });
      }

      if (oldVersion < 21) {
        const appDevelopmentVersionStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.APP_DEVELOPMENT_VERSION_STORE, { keyPath: 'entity.id' });
        appDevelopmentVersionStore.createIndex(ObjectStores.APP_ID_INDEX, 'entity.appId', { unique: false });
        appDevelopmentVersionStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 22) {
        const themesStore: IDBObjectStore = this.db.createObjectStore(ObjectStores.MINI_APP_VIEW_THEMES_STORE,
          { keyPath: 'entity.id' });
        themesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        themesStore.createIndex(ObjectStores.MINI_APP_ID_INDEX, 'entity.miniAppId', { unique: false });
      }

      if (oldVersion < 23) {
        const appReleasesStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.APP_RELEASES_STORE, { keyPath: 'entity.id' });
        appReleasesStore.createIndex(ObjectStores.APP_ID_INDEX, 'entity.appId', { unique: false });
        appReleasesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const appEnvironmentStore:IDBObjectStore = this.db.createObjectStore(
          ObjectStores.APP_ENVIRONMENT_STORE, { keyPath: 'entity.id' });
        appEnvironmentStore.createIndex(ObjectStores.APP_ID_INDEX, 'entity.appId', { unique: false });
        appEnvironmentStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        const deployedAppStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.DEPLOYED_APP_STORE, { keyPath: 'entity.environmentId' });
        deployedAppStore.createIndex(ObjectStores.APP_ID_INDEX, 'entity.appId', { unique: false });
        deployedAppStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });

        // releasedAppStore
        this.db.createObjectStore(
          ObjectStores.RELEASED_APP_STORE, { keyPath: 'entity.releaseId' });

        const deploymentHistoryStore = this.db.createObjectStore(
          ObjectStores.DEPLOYMENT_HISTORY_STORE, { keyPath: 'entity.id' });
        deploymentHistoryStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
      }

      if (oldVersion < 24) {
        // This won't exist unless the browser has pre-existing state. In clean browsers this store's never created.
        if (this.db.objectStoreNames.contains(ObjectStores.SEMANTIC_TAGS_STORE)) {
          this.db.deleteObjectStore(ObjectStores.SEMANTIC_TAGS_STORE);
        }
      }

      if (oldVersion < 25) {
        const conditionalOverridesStore: IDBObjectStore = this.db.createObjectStore(
          ObjectStores.MINI_APP_VIEW_CONDITIONAL_OVERRIDES_STORE, { keyPath: 'entity.id' });
        conditionalOverridesStore.createIndex(ObjectStores.TIMESTAMP_INDEX, 'timestamp', { unique: false });
        conditionalOverridesStore.createIndex(ObjectStores.MINI_APP_ID_INDEX, 'entity.miniAppId', { unique: false });
      }

      if (request.transaction) {
        request.transaction.oncomplete = () => {
          log.debug('open.onupgradeneeded.resolve');
          resolve();
        };
      } else {
        resolve();
      }
    });
  }

  protected async handleExceededQuota(): Promise<void> {
    // TODO: By this point there will already have been failed database inserts, so we might
    // want to tell the user that things might start behaving strangely until they refresh the page.
    if (!this.exceededQuotaHandled) {
      log.debug('handling exceeded quota');
      this.exceededQuotaHandled = true;
      const currentUserRecords: FullUserDetails[] = await this.getAll(ObjectStores.CURRENT_USER_STORE);
      if (currentUserRecords && currentUserRecords.length > 0) {
        const currentUser: FullUserDetails = currentUserRecords[0];
        const memberships: Member[] = (await this.getByIndex(ObjectStores.TRACK_MEMBERS_STORE,
          ObjectStores.USER_ID_INDEX, currentUser.id)).map((item: StoredItem<Member>) => item.entity);
        // If we have 4 or more tracks, remove some of the data
        if (memberships.length > 3) {
          memberships.sort((a: Member, b: Member) => {
            const aLastOnline: number = a.lastOnline || 0;
            const bLastOnline: number = b.lastOnline || 0;
            return aLastOnline - bLastOnline;
          });
          // Remove track data for the oldest 25% of the tracks
          const promises: Array<Promise<void>> = [];
          const maxIndex: number = Math.ceil(memberships.length / 4);
          log.debug(`removing ${maxIndex} sets of track data`);
          for (let i = 0; i < maxIndex; ++i) {
            const trackId: string = memberships[i].trackId;
            promises.push(this.removeObjectsByTrackId<Member>(trackId, ObjectStores.TRACK_MEMBERS_STORE));
            promises.push(this.removeObjectsByTrackId<TrackEntry>(trackId, ObjectStores.TRACK_ENTRIES_STORE));
            promises.push(this.removeObjectsByTrackId<ChatMessage>(trackId, ObjectStores.CHAT_MESSAGES_STORE));
            promises.push(this.removeObjectsByTrackId<ChatMessage>(trackId, ObjectStores.COMMENTS_STORE));
            promises.push(this.removeObjectsByTrackId<TextFile>(trackId, ObjectStores.ENTRY_TEXT_FILES_STORE));
            promises.push(this.removeObjectsByTrackId<TextFile>(trackId, ObjectStores.PINNED_TRACK_ITEMS_STORE));
            promises.push(this.removeObjectsByTrackId<MiniApp>(trackId, ObjectStores.TRACK_MINI_APPS_STORE));
            promises.push(this.removeObjectsByTrackId<TasksTable>(trackId, ObjectStores.TRACK_TASKS_TABLE_STORE));
            promises.push(this.removeObjectsByTrackId<Task>(trackId, ObjectStores.TASKS_STORE));
            promises.push(this.removeObjectsByTrackId<Task>(trackId, ObjectStores.CHAT_HISTORY_STATE_STORE));
            promises.push(this.removeObjectsByTrackId<EntryThumbnail>(trackId, ObjectStores.ENTRY_THUMBNAIL_STORE));
          }
          await Promise.all(promises);
        }
      }
      // Wait a few ms in case the deletions don't reduce the size immediately
      setTimeout(() => {
        this.exceededQuotaHandled = false;
      }, 500);
    } else {
      log.debug('Exceeded quota already being handled');
    }
  }

  private async removeObjectsByTrackId<T extends EntityWithId>(trackId: string, objectStoreName: string):
    Promise<void> {
    const promises: Array<Promise<void>> = [];
    const items: T[] = (await this.getByIndex(objectStoreName,
      ObjectStores.TRACK_ID_INDEX, trackId)).map((item: StoredItem<T>) => item.entity);
    for (const item of items) {
      promises.push(this.delete(objectStoreName, item.id));
    }
    await Promise.all(promises);
  }

  protected handleVersionChangeRequest(): void {
    // A new tab has been opened with a newer version of the app that includes an IDB upgrade.
    this.dbUpgradeRequestedCallback(this.db);
  }
}
